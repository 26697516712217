import React, { Component, Fragment } from 'react'
import PostWysiwyg from '@/components/PostWysiwyg/PostWysiwyg'
import SEO from '@/components/SEO/SEO'

export default class Standard extends Component {
	render() {
		const { entry, location } = this.props

		const {
			body: { content: body },
			title
		} = entry

		const {
			socialMetaTitle,
			socialMetaDescription,
			seoTitle,
			seoDescription,
			subtitle
		} = entry

		return (
			<Fragment>
				<SEO
					title={seoTitle || entry.title}
					description={seoDescription || subtitle}
					socialTitle={socialMetaTitle || entry.title}
					socialDesc={socialMetaDescription || subtitle}
					url={location.href}
				/>
				<div className="bg-white mt-4 pt-2 pb-2 lg:pb-4 lg:mt-0">
					<div className="wrapper">
						<div className="lg:w-16/24">
							<h1 className="lowercase mb-2 lg:mb-3 text-xlh-fluid text-black font-bold relative">
								<span className="absolute block h-5 w-screen vert-center pin-r-100 bg-teal mr-2" />
								{title}
							</h1>
							<PostWysiwyg>
								<div
									className="text-black"
									dangerouslySetInnerHTML={{ __html: body }}
								/>
							</PostWysiwyg>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
